// import styles from "./Block.css";

const blockStyle = () => ( {
  display: 'flex',
  padding: '10vh 10vw',
  'align-items': 'center',
  'height': 'auto'
  }
)

export default blockStyle;